<template>
    <Head>
        <title>SwingU Courses</title>
        <meta
            type="description"
            content="Default meta description"
            head-key="description"
        />
        <meta charset="utf-8" />

        <link
            rel="apple-touch-icon"
            sizes="180x180"
            href="/apple-touch-icon.png"
        />
        <link
            rel="icon"
            type="image/png"
            sizes="32x32"
            href="/favicon-32x32.png"
        />
        <link
            rel="icon"
            type="image/png"
            sizes="16x16"
            href="/favicon-16x16.png"
        />
        <link rel="manifest" href="/site.webmanifest" />

        <meta
            name="viewport"
            content="width=device-width, initial-scale=1.0, maximum-scale=1.0"
        />
        <link rel="stylesheet" href="https://rsms.me/inter/inter.css" />
    </Head>
    <body
        data-theme="default"
        :data-courses="true"
        class="antialiased relative font-swingu mx-auto"
    >
        <SearchOverlay />
        <LayoutHeader />
        <main class="pt-[94px]">
            <slot />
        </main>
        <LayoutFooter
            class="mt-[100px]"
        />
    </body>
</template>

<script setup>
import SearchOverlay from "@global/ui/search-overlay.vue";
import LayoutHeader from "@global/layout/swingu-com/layout-header.vue";
import LayoutFooter from "@global/layout/swingu-com/layout-footer.vue";

const props = defineProps({
    subdomainData: {},
    layoutData: {},
});

</script>

<script>
export default {
    data() {
        return {};
    },
    methods: {},
};
</script>
